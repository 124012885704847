"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function InlineResponse200MetaCategoriesFromJSON(json) {
    return InlineResponse200MetaCategoriesFromJSONTyped(json, false);
}
exports.InlineResponse200MetaCategoriesFromJSON = InlineResponse200MetaCategoriesFromJSON;
function InlineResponse200MetaCategoriesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'slug': !runtime_1.exists(json, 'slug') ? undefined : json['slug'],
        'count': !runtime_1.exists(json, 'count') ? undefined : json['count'],
    };
}
exports.InlineResponse200MetaCategoriesFromJSONTyped = InlineResponse200MetaCategoriesFromJSONTyped;
function InlineResponse200MetaCategoriesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'slug': value.slug,
        'count': value.count,
    };
}
exports.InlineResponse200MetaCategoriesToJSON = InlineResponse200MetaCategoriesToJSON;
