import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './EmergencyMessage.module.scss';
import Icon from '../Icon';

const EmergencyMessage = ({ message }) => {
    return (
        message && (
            <div className={styles['EmergencyMessage']}>
                <div className={styles['EmergencyMessage__Content']}>
                    <div className={styles['EmergencyMessage__Icon']}><Icon type="error" color="white" size="medium" /></div>
                    <div
                        className={styles['EmergencyMessage__Text']}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                </div>
            </div>
        )
    );
};

EmergencyMessage.propTypes = {
    message: PropTypes.string,
};

EmergencyMessage.defaultProps = {
    message: null,
};

export default EmergencyMessage;
