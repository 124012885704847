"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function CardGuideBylineFromJSON(json) {
    return CardGuideBylineFromJSONTyped(json, false);
}
exports.CardGuideBylineFromJSON = CardGuideBylineFromJSON;
function CardGuideBylineFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'image': !runtime_1.exists(json, 'image') ? undefined : json['image'],
        'author': _1.CardGuideBylineAuthorFromJSON(json['author']),
        'color': !runtime_1.exists(json, 'color') ? undefined : json['color'],
        'size': !runtime_1.exists(json, 'size') ? undefined : json['size'],
        'headline': !runtime_1.exists(json, 'headline') ? undefined : json['headline'],
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
    };
}
exports.CardGuideBylineFromJSONTyped = CardGuideBylineFromJSONTyped;
function CardGuideBylineToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'author': _1.CardGuideBylineAuthorToJSON(value.author),
    };
}
exports.CardGuideBylineToJSON = CardGuideBylineToJSON;
