import React from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styles from './CardEvent.module.scss';
import { ucFirst } from '../../utils/caseconverters';
import { DATE_FORMAT, LOCALE, isSameDate } from '../../utils/date';

const CardEvent = ({
    id,
    image,
    startDate,
    endDate,
    label,
    color,
    title,
    date,
    description,
    categories,
    content,
    onClick,
    address,
    modifiers,
    href,
    size,
    className,
    showRemoveButton,
    removeEvent,
    setActive,
    inSlider,
}) => {
    const isHorizontal = content;

    const handleRemoveEvent = (e) => {
        e.preventDefault();
        removeEvent(id);
    };

    const handleMouseEnter = () => {
        setActive(id);
    };
    let iconColor =
        isHorizontal || modifiers.includes('White') ? 'black' : 'gray100';

    const ContainerComponent = href ? 'a' : 'div';

    return (
        <ContainerComponent
            href={href}
            onMouseEnter={showRemoveButton ? handleMouseEnter : null}
            onClick={onClick}
            className={classNames(
                className,
                styles['CardEvent'],
                {
                    [styles['CardEvent--Horizontal']]: isHorizontal,
                },
                modifiers.map((mod) => styles['CardEvent--' + mod])
            )}>
            <div
                className={classNames(
                    className,
                    styles['CardEventPicture'],
                    styles['CardEventPicture--' + ucFirst(size)]
                )}>
                {image && image.renditions && (
                    <div
                        className={classNames(
                            styles['CardEventPicture__Picture'],
                            styles[
                                'CardEventPicture__Picture--' + ucFirst(size)
                            ]
                        )}>
                        {image && image.renditions && (
                            <LazyLoad once>
                                <img
                                    sizes="343px"
                                    srcSet={`${image.renditions.medium.src} 600w,
                                     ${image.renditions.large.src} 1200w`}
                                    src={image.renditions.medium.src}
                                    alt={image.alt}
                                    style={{
                                        objectPosition:
                                            image.focal.x + ' ' + image.focal.y,
                                    }}
                                />
                            </LazyLoad>
                        )}

                        {/*label && !isHorizontal && (
                            <div
                                className={
                                    styles['CardEventPicture__PictureLabel']
                                }>
                                {label}
                            </div>
                        )*/}

                        <div
                            className={styles['CardEventPicture__PictureDate']}>
                            {date && date.month && (
                                <div
                                    className={classNames(
                                        styles[
                                            'CardEventPicture__DateBackground'
                                        ],
                                        styles[
                                            `CardEventPicture__DateBackground--${ucFirst(
                                                size
                                            )}`
                                        ],
                                        styles[
                                            `CardEventPicture__DateBackground--${ucFirst(
                                                color
                                            )}`
                                        ]
                                    )}
                                />
                            )}

                            {date && date.month && (
                                <div
                                    className={classNames(
                                        styles[
                                            'CardEventPicture__DateContainer'
                                        ],
                                        {
                                            [styles[
                                                'CardEventPicture__DateContainer--SmallOnDesktop'
                                            ]]: size === 'small',
                                        }
                                    )}>
                                    <span
                                        className={classNames(
                                            styles[
                                                'CardEventPicture__DateContainer--Day'
                                            ],
                                            {
                                                [styles[
                                                    'CardEventPicture__DateContainer--Small'
                                                ]]: size === 'small',
                                                [styles[
                                                    'CardEventPicture__DateContainer--Large'
                                                ]]: size === 'large',
                                            }
                                        )}>
                                        {date.day}
                                    </span>

                                    <span
                                        className={classNames(
                                            styles[
                                                'CardEventPicture__DateContainer--Month'
                                            ],
                                            {
                                                [styles[
                                                    'CardEventPicture__DateContainer--Small'
                                                ]]: size === 'small',
                                            }
                                        )}>
                                        {date.month &&
                                            date.month.substring(0, 3)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <div className={styles['CardEvent__Content']}>
                {title && (
                    <strong className={styles['CardEvent__Title']}>
                        {title}
                    </strong>
                )}

                {label && showRemoveButton && (
                    <p className={styles['CardEvent__Label']}>{label}</p>
                )}

                {description && !inSlider && (
                    <div className={styles['CardEvent__Description']}>
                        <p>{description}</p>
                    </div>
                )}

                {categories && !showRemoveButton && (
                    <p
                        className={styles['CardEvent__Category']}
                        dangerouslySetInnerHTML={{
                            __html: categories.join(' &bull; '),
                        }}
                    />
                )}

                {showRemoveButton && (
                    <div
                        className={styles['CardEvent__RemoveButton']}
                        onClick={(e) => handleRemoveEvent(e)}>
                        <Icon
                            type="close"
                            size="small"
                            color="gray500"
                            modifiers={[styles['CardEvent__IconSize']]}
                        />
                        <div className={styles['CardEvent__RemoveText']}>
                            {i18n.t('generic.remove')}
                        </div>
                    </div>
                )}

                <div className={styles['CardEvent__Meta']}>
                    {!!startDate && !!endDate && (
                        <div className={styles['CardEvent__MetaRow']}>
                            <div className={styles['CardEvent__IconWrapper']}>
                                <Icon
                                    size="small"
                                    type="calender"
                                    color={iconColor}
                                />
                            </div>

                            <span>
                                {isSameDate(startDate, endDate)
                                    ? startDate.toLocaleString(
                                          LOCALE,
                                          DATE_FORMAT
                                      )
                                    : [
                                          startDate.toLocaleDateString(
                                              LOCALE,
                                              DATE_FORMAT
                                          ),
                                          endDate.toLocaleDateString(
                                              LOCALE,
                                              DATE_FORMAT
                                          ),
                                      ].join(' - ')}
                            </span>
                        </div>
                    )}

                    {address && isHorizontal && (
                        <div className={styles['CardEvent__MetaRow']}>
                            <div className={styles['CardEvent__IconWrapper']}>
                                <Icon
                                    size="small"
                                    type="place"
                                    color={isHorizontal ? 'black' : 'gray100'}
                                />
                            </div>
                            <span>{address}</span>
                        </div>
                    )}
                </div>
            </div>
        </ContainerComponent>
    );
};

CardEvent.propTypes = {
    id: PropTypes.number,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    image: PropTypes.object,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    href: PropTypes.string,
    label: PropTypes.string,
    categories: PropTypes.array,
    title: PropTypes.string,
    color: PropTypes.oneOf(['blue50', 'green50', 'purple50', 'orange50']),
    content: PropTypes.bool,
    onClick: PropTypes.func,
    address: PropTypes.string,
    description: PropTypes.string,
    modifiers: PropTypes.array,
    className: PropTypes.string,
    showRemoveButton: PropTypes.bool,
    removeEvent: PropTypes.func,
    setActive: PropTypes.func,
};

CardEvent.defaultProps = {
    id: null,
    size: 'small',
    image: null,
    date: null,
    startDate: null,
    endDate: null,
    label: null,
    href: null,
    title: null,
    categories: null,
    color: 'blue50',
    content: false,
    onClick: () => {},
    address: null,
    description: null,
    modifiers: [],
    className: '',
    inSlider: false,
    showRemoveButton: false,
    removeEvent: () => {},
    setActive: () => {},
};

export default CardEvent;
