import React, { useState, useEffect } from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './RippleEffect.module.scss';

const useDebouncedRippleCleanUp = (numberOfRipples, time, cleanUpRipples) => {
    useEffect(() => {
        let bounce = null;
        if (numberOfRipples > 0) {
            clearTimeout(bounce);
            bounce = setTimeout(() => {}, time * 4);
        }

        return () => {
            clearTimeout(bounce);
        };
    }, [numberOfRipples, time, cleanUpRipples]);
};

const RippleEffect = ({ time = 850, color = '#000' }) => {
    const [rippleArray, setRippleArray] = useState([]);

    useDebouncedRippleCleanUp(rippleArray.length, time, () => {
        setRippleArray([]);
    });

    const addRipple = (event) => {
        const RippleContainer = event.currentTarget.getBoundingClientRect();

        const size =
            RippleContainer.width > RippleContainer.height
                ? RippleContainer.width
                : RippleContainer.height;

        const leftPosition =
            event.pageX - RippleContainer.x - RippleContainer.width / 2;

        const topPosition =
            event.pageY - RippleContainer.y - RippleContainer.width / 2;

        const RippleInstance = {
            leftPosition,
            topPosition,
            size,
        };
        setRippleArray((prevState) => [...prevState, RippleInstance]);
    };

    return (
        <div
            className={styles['RippleEffect__Container']}
            onMouseDown={addRipple}>
            {rippleArray &&
                rippleArray.length > 0 &&
                rippleArray.map((ripple, index) => {
                    return (
                        <span
                            className={styles['RippleEffect__Circle']}
                            key={'span' + index}
                            style={{
                                top: ripple.topPosition,
                                left: ripple.leftPosition,
                                width: ripple.size,
                                height: ripple.size,
                                animationDuration: `"${time}ms"`,
                                backgroundColor: color,
                            }}
                        />
                    );
                })}
        </div>
    );
};

RippleEffect.propTypes = {
    time: PropTypes.number,
    color: PropTypes.string,
};

RippleEffect.defaultProps = {
    time: null,
    color: null,
};

export default RippleEffect;
