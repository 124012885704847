import React from 'react';
import PropTypes from 'prop-types';

import { snakeToPascal } from '../../utils/caseconverters';
import styles from './StreamField.module.scss';
import dynamic from 'next/dynamic';

const RichTextBlock = dynamic(() => import('../RichText'));
const CrossLinksBlock = dynamic(() => import('../CrossLinksBlock'));
const ArticleBlock = dynamic(() => import('../ArticleBlock'));
const CardListLocals = dynamic(() => import('../CardListLocals'));
const CardList = dynamic(() => import('../../components/CardList'));
const GuideList = dynamic(() => import('../../components/GuideList'));
const EventPageContent = dynamic(() =>
    import('../../components/EventPageContent')
);
const FeaturedEvent = dynamic(() => import('../../components/FeaturedEvent'));
const GuideTeaser = dynamic(() => import('../../components/GuideTeaser'));
const LocalsPresentation = dynamic(() =>
    import('../../components/LocalsPresentation')
);
const EventSubmission = dynamic(() =>
    import('../../components/EventSubmission')
);
const FeaturedArticles = dynamic(() => import('../FeaturedArticles'));
const Video = dynamic(() => import('../Video'));
const BlockQuote = dynamic(() => import('../BlockQuote'));
const Form = dynamic(() => import('../Form'));
const FactBox = dynamic(() => import('../FactBox'));
const TextBlock = dynamic(() => import('../TextBlock'));
const RibbonLink = dynamic(() => import('../RibbonLink'));
const SubStartSegmentation = dynamic(() => import('../SubStartSegmentation'));
const News = dynamic(() => import('../News'));
const Infographics = dynamic(() => import('../Infographics'));
const ThinInspiration = dynamic(() => import('../ThinInspiration'));
const Inspiration = dynamic(() => import('../Inspiration'));
const FaqList = dynamic(() => import('../FaqList'));
const Contact = dynamic(() => import('../Contact'));
const ContactList = dynamic(() => import('../ContactList'));
const LogotypeList = dynamic(() => import('../LogotypeList'));
const LinksMosaic = dynamic(() => import('../LinksMosaic'));
const OrganizationTree = dynamic(() => import('../OrganizationTree'));
const Table = dynamic(() => import('../Table'));
const CalendarList = dynamic(() => import('../CalendarList'));
const ContactBlock = dynamic(() => import('../ContactBlock'));

const Components = {
    RichTextBlock,
    CrossLinksBlock,
    CardListBlock: CardList,
    ArticleBlock,
    FilteredEventListBlock: CardList,
    CardListTwoArticles: CardList,
    CardListLocals,
    CardList,
    GuideList,
    EventPageContent,
    FeaturedEvent,
    GuideTeaser,
    LocalsPresentation,
    EventSubmission,
    FeaturedArticles,
    Video,
    BlockQuote,
    Form,
    FactBox,
    TextBlock,
    RibbonLink,
    SubStartSegmentation,
    News,
    Infographics,
    ThinInspiration,
    Inspiration,
    FaqList,
    Contact: ContactBlock,
    LogotypeList,
    ContactList,
    LinksMosaic,
    OrganizationTree,
    Table,
    EventList: CalendarList,
};

const ComponentColors = {
    RichTextBlock: 'White',
    CrossLinksBlock: 'White',
    CardListBlock: '',
    ArticleBlock: 'White',
    FilteredEventListBlock: 'Black',
    CardListTwoArticles: 'Black',
    CardListLocals: 'Mixed',
    CardList: '',
    GuideList: 'Mixed',
    EventPageContent: 'Black',
    FeaturedEvent: 'Black',
    GuideTeaser: '',
    FeaturedArticles: 'Black',
    Video: 'White',
    TextBlock: 'White',
    RibbonLink: 'LargePadding',
    SubStartSegmentation: 'White',
    News: 'White',
    Infographics: 'White',
    ThinInspiration: 'LargePadding',
    Inspiration: 'LargePadding',
    FaqList: 'White',
    Contact: 'White',
    LogotypeList: 'White',
    ContactList: 'White',
    LinksMosaic: 'LargePadding',
    OrganizationTree: 'White',
    Table: 'White',
    BlockQuote: 'White',
    CalendarList: 'White',
};

const StreamField = ({ items, componentWrapperExtraClass }) => {
    if (!items.length) {
        return null;
    }

    const dynamicComponents = items.map((item) => {
        item.component = snakeToPascal(item.type);
        item.color = item.value?.color || ComponentColors[item.component] || '';
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        return (
            <div
                key={item.id}
                id={`sf-${item.id}`}
                className={[
                    styles['StreamField__Component'],
                    styles[`StreamField__Component--${item.component}`],
                    styles[`StreamField__Component--${item.color}`],
                    componentWrapperExtraClass,
                ].join(' ')}>
                <Component id={item.id} {...item.value} color={item.color} />
            </div>
        );
    });

    return <>{dynamicComponents}</>;
};

StreamField.propTypes = {
    items: PropTypes.array,
    componentWrapperExtraClass: PropTypes.string,
};

StreamField.defaultProps = {
    items: [],
    componentWrapperExtraClass: '',
};

export default StreamField;
