import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import RippleEffect from '../RippleEffect';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import { ucFirst } from '../../utils/caseconverters';

const Button = ({
    type,
    submit,
    href,
    onClick,
    isClicked,
    disabled,
    icon,
    text,
    iconSize,
    buttonColor,
    iconColor,
    modifiers,
    sizeXLMobile,
    name,
    reverseOrder,
}) => {
    const isLink = href;

    const classes = classNames(
        modifiers.map((x) => styles[x] + ' '),
        styles['Button__Container'],
        styles['Button--' + ucFirst(type)],
        styles['Button--' + ucFirst(buttonColor)],
        { [styles['Button--NoIcon']]: !icon },
        { [styles['Button--IsClicked']]: isClicked }
    );

    const innerContent = (
        <>
            <span
                className={classNames(
                    'Button__Content',
                    styles['Button__Content'],
                    styles['Button__Content--' + ucFirst(buttonColor)],
                    {
                        [styles['Button__Content--TertiaryHover']]:
                            ucFirst(type) === 'Tertiary',
                    },
                    {
                        [styles['Button__Content--Reverse']]: reverseOrder,
                    }
                )}>
                {text}{' '}
                {icon && (
                    <div className={styles['Button__IconContainer']}>
                        <Icon
                            type={icon}
                            size={iconSize}
                            color={iconColor}
                            sizeXLMobile={sizeXLMobile}
                        />
                    </div>
                )}
            </span>
            <RippleEffect
                time={10}
                color={type === 'primary' ? '#c80058' : '#434343'}
            />
        </>
    );

    return (
        <div
            className={
                styles['Button'] + modifiers.map((x) => ' ' + x).join(' ')
            }>
            {isLink ? (
                <a
                    className={classes}
                    href={href}
                    disabled={disabled}
                    onClick={onClick}>
                    {innerContent}
                </a>
            ) : (
                <button
                    aria-label={name}
                    className={classes}
                    disabled={disabled}
                    type={submit ? 'submit' : 'button'}
                    onClick={onClick}>
                    {innerContent}
                </button>
            )}
        </div>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'secondaryInvert',
    ]),
    iconSize: PropTypes.oneOf(['small', 'medium', 'large', 'xl']),
    buttonColor: PropTypes.oneOf(['', 'pink', 'white', 'black']),
    href: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    submit: PropTypes.bool,
    icon: PropTypes.string,
    pressed: PropTypes.bool,
    iconColor: PropTypes.string,
    modifiers: PropTypes.array,
    sizeXLMobile: PropTypes.bool,
    reverseOrder: PropTypes.bool,
    name: PropTypes.string,
};

Button.defaultProps = {
    type: 'primary',
    href: null,
    text: null,
    onClick: () => {},
    icon: null,
    disabled: false,
    submit: false,
    pressed: false,
    iconSize: 'medium',
    iconColor: 'white',
    buttonColor: 'pink',
    modifiers: [],
    isClicked: false,
    sizeXLMobile: null,
    reverseOrder: false,
    name: '',
};

export default Button;
