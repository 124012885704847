"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function ObjectTeaserFromJSON(json) {
    return ObjectTeaserFromJSONTyped(json, false);
}
exports.ObjectTeaserFromJSON = ObjectTeaserFromJSON;
function ObjectTeaserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'label': json['label'],
        'text': json['text'],
        'href': json['href'],
        'image': _1.CardGuideImageFromJSON(json['image']),
        'location': _1.EventOccurrenceLocationFromJSON(json['location']),
        'slug': json['slug'],
        'infoBox': !runtime_1.exists(json, 'info_box') ? undefined : json['info_box'],
        'infoBoxText': !runtime_1.exists(json, 'info_box_text') ? undefined : json['info_box_text'],
        'massInfo': (json['mass_info'].map(_1.ObjectTeaserMassInfoFromJSON)),
    };
}
exports.ObjectTeaserFromJSONTyped = ObjectTeaserFromJSONTyped;
function ObjectTeaserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'label': value.label,
        'text': value.text,
        'href': value.href,
        'image': _1.CardGuideImageToJSON(value.image),
        'location': _1.EventOccurrenceLocationToJSON(value.location),
        'slug': value.slug,
        'mass_info': (value.massInfo.map(_1.ObjectTeaserMassInfoToJSON)),
    };
}
exports.ObjectTeaserToJSON = ObjectTeaserToJSON;
