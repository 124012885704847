import React from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './Contact.module.scss';

const Contact = ({
    title,
    jobTitle,
    phoneNumber,
    mobilePhoneNumber,
    email,
    image,
    fullName,
}) => {
    return (
        <div className={styles['Contact']}>
            {!!image?.renditions?.medium?.src && (
                <figure className={styles['Contact__Image']}>
                    <img
                        className={styles['Contact__ImageComponent']}
                        src={image.renditions.medium.src}
                        alt={image?.alt}
                    />
                </figure>
            )}
            <div className={styles['Contact__ImageContent']}>
                {(!!title || !!fullName) && (
                    <p className={styles['Contact__Title']}>
                        {title || fullName}
                    </p>
                )}
                {!!jobTitle && (
                    <p
                        className={classNames(
                            styles['Contact__Text'],
                            styles['Contact__Text--JobTitle']
                        )}>
                        {jobTitle}
                    </p>
                )}
                {!!phoneNumber && (
                    <p className={styles['Contact__Text']}>
                        {i18n.t('generic.phone')}: {phoneNumber}
                    </p>
                )}
                {!!mobilePhoneNumber && (
                    <p className={styles['Contact__Text']}>
                        {i18n.t('generic.mobile')}: {mobilePhoneNumber}
                    </p>
                )}
                {!!email && (
                    <p className={styles['Contact__Text']}>
                        {i18n.t('generic.email')}:{' '}
                        <a
                            href={`mailto:${email}`}
                            className={styles['Contact__Link']}>
                            {email}
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
};

Contact.propTypes = {
    title: PropTypes.string,
    fullName: PropTypes.string,
    jobTitle: PropTypes.string,
    department: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.object,
};

Contact.defaultProps = {
    title: '',
    fullName: '',
    jobTitle: '',
    department: '',
    phoneNumber: '',
    mobilePhoneNumber: '',
    email: '',
    image: {},
};

export default Contact;
