import React from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';
import { ucFirst } from '../../utils/caseconverters';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
import LazyLoad from 'react-lazyload';

import Byline from '../Byline';

const Card = ({
    href,
    title,
    image,
    category,
    quote,
    size,
    byline,
    modifiers,
    color,
    type,
}) => {
    const hasByline = !!byline;

    return (
        <a
            href={href}
            className={classNames(
                modifiers.map((x) => ' ' + x),
                styles['Card'],
                styles['Card--' + ucFirst(size)],
                styles['Card--' + type],
                {
                    [styles['Card--WithByline']]: hasByline,
                    [styles['Card--' + color]]: color,
                }
            )}>
            <div className={styles['Card__Wrapper']}>
                <LazyLoad once style={{ width: '100%', height: '100%' }}>
                    <div className={styles['Card__Picture']}>
                        {image && image.renditions && (
                            <img
                                sizes="343px"
                                srcSet={`${image.renditions.medium.src} 600w,
                                         ${image.renditions.large.src} 1200w`}
                                src={image.renditions.medium.src}
                                alt={image.alt}
                                style={{
                                    objectPosition:
                                        image.focal.x + ' ' + image.focal.y,
                                }}
                            />
                        )}
                    </div>
                </LazyLoad>

                <div className={styles['Card__Shadow']}></div>
                <div
                    className={classNames(styles['Card__Information'], {
                        [styles['Card__InformationByline']]: hasByline,
                        [styles['Card--HasQuote']]: quote,
                    })}>
                    {color !== 'White' && (
                        <span
                            className={classNames(
                                styles['Card__InformationHeading'],
                                {
                                    [styles[
                                        'Card__InformationHeading--AddMargin'
                                    ]]: !category && !quote,
                                }
                            )}>
                            {title && title}
                        </span>
                    )}

                    {category && color !== 'White' && (
                        <p className={styles['Card__InformationCategory']}>
                            <span className="sr-only">
                                {i18n.t('generic.categories')} :
                            </span>
                            {category}
                        </p>
                    )}

                    {quote && color !== 'White' && (
                        <blockquote
                            className={styles['Card__InformationQuote']}>
                            "{quote}"
                        </blockquote>
                    )}
                    <div>
                        {byline && (
                            <Byline
                                image={byline.image}
                                author={byline.author}
                                size="small"
                                modifiers={[styles['Card__Byline']]}
                            />
                        )}
                    </div>
                </div>
            </div>
            {color === 'White' && (
                <div className={styles['Card__Information-wrapper']}>
                    <span className={styles['Card__InformationHeading']}>
                        {title && title}
                    </span>

                    {category && (
                        <p className={styles['Card__InformationCategory']}>
                            <span className="sr-only">
                                {i18n.t('generic.categories')} :
                            </span>
                            {category}
                        </p>
                    )}
                    {quote && (
                        <blockquote
                            className={styles['Card__InformationQuote']}>
                            "{quote}"
                        </blockquote>
                    )}
                </div>
            )}
        </a>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    byline: PropTypes.object,
    quote: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'full']),
    modifiers: PropTypes.array,
    color: PropTypes.string,
    type: PropTypes.string,
};

Card.defaultProps = {
    title: '',
    image: null,
    byline: null,
    quote: null,
    size: 'medium',
    modifiers: [],
    color: '',
    type: '',
};

export default Card;
