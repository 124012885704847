import React from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './Breadcrumbs.module.scss';
import Icon from '../../components/Icon';

const Breadcrumbs = ({ links }) =>
    !!links.length && (
        <div className={styles['Breadcrumbs']}>
            <nav className={styles['Breadcrumbs__Nav']}>
                <span className="sr-only">
                        {i18n.t('generic.breadcrumbs') + ': '}
                    </span>
                <ol className={styles['Breadcrumbs__List']}>
                    {links.map((link, idx) => (
                        <li className={styles['Breadcrumbs__Item']} key={idx}>
                            {idx !== 0 && <Icon type="chevron" size="xs" />}

                            <a
                                href={link.href}
                                className={styles['Breadcrumbs__Link']}>
                                {link.label}
                            </a>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    );

Breadcrumbs.propTypes = {
    links: PropTypes.array.isRequired,
};

Breadcrumbs.defaultProps = {
    links: [],
};

export default Breadcrumbs;
