import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styles from './ShareModal.module.scss';
import {
    hasNativeShareSupport,
    triggerNativeShareDialog,
} from '../../utils/nativeShare';
import copy from 'copy-to-clipboard';
import i18n from '../../i18n';
import Modal from '../Modal';
import Icon from '../Icon';
import ButtonRound from '../ButtonRound';
import Button from '../Button';
import { trackEvent } from '../../utils/datalayer';

const ShareModal = ({ isOpen, title, handleClose, url, categories }) => {
    const [showModal, setShowModal] = useState(isOpen);
    const [shareLink, setShareLink] = useState('');

    useEffect(() => handleOpenModal(isOpen), [isOpen]);

    const handleOpenModal = (isOpen) => {
        if (isOpen === true) {
            let shareUrl = url;
            if (!shareUrl.startsWith("http")) {
                shareUrl = url
                    ? document.location.origin + url
                    : window.location;
            }
            if (hasNativeShareSupport()) {
                return triggerNativeShareDialog(
                    i18n.t('generic.shareText'),
                    shareUrl
                );
            }
            document.body.style.overflow = 'hidden';
            setShareLink(shareUrl);
        }

        setShowModal(isOpen);
    };

    const handleCloseModal = () => {
        document.body.style.overflow = 'visible';
        setShowModal(false);
        handleClose(false);
    };

    const getShareLinkSM = () => {
        const encodedUrl = encodeURIComponent(shareLink);
        return {
            facebook: {
                title: title,
                text: i18n.t('generic.shareText'),
                url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&amp;src=sdkpreparse`,
            },
            mail: `mailto: ?subject=Visit Stockholm&body=${
                i18n.t('generic.shareWithMail') + shareLink
            }`,
        };
    };

    const shareLinkFb = () => {
        trackEvent('share', 'Engagement', 'Share', 'Facebook', {
            objectHeadline: title,
            objectCategory: categories,
        });
        window.open(getShareLinkSM().facebook.url);
    };

    const copyShareLinkToClipboard = () => {
        trackEvent('share', 'Engagement', 'Share', 'Link', {
            objectHeadline: title,
            objectCategory: categories,
        });
        copy(shareLink);
    };

    return (
        <div>
            {showModal && (
                <Modal handleCloseModal={handleCloseModal}>
                    <div className={styles['ShareModal']}>
                        <button
                            aria-label={i18n.t('Modal.close')}
                            label={i18n.t('Modal.close')}
                            className={styles['ShareModal__ModalCloseButton']}
                            onClick={handleCloseModal}>
                            <Icon type="close" color="black800" size="large" />
                        </button>

                        <strong className={styles['ShareModal__ModalTitle']}>
                            {i18n.t('Modal.modalTitle')}
                        </strong>

                        <div
                            className={
                                styles['ShareModal__ModalButtonsWrapper']
                            }>
                            <ButtonRound
                                type="facebook"
                                modalSize={true}
                                onClick={shareLinkFb}
                            />
                            <ButtonRound
                                type="mail"
                                modalSize={true}
                                href={getShareLinkSM().mail}
                                onClick={() =>
                                    trackEvent(
                                        'share',
                                        'Engagement',
                                        'Share',
                                        'Mail',
                                        {
                                            objectHeadline: title,
                                            objectCategory: categories,
                                        }
                                    )
                                }
                            />
                        </div>

                        <input
                            className={styles['ShareModal__ModalInput']}
                            type="text"
                            readOnly
                            value={shareLink}
                        />

                        <Button
                            type="secondary"
                            text="Copy link"
                            onClick={copyShareLinkToClipboard}
                            iconColor="black800"
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

ShareModal.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    handleClose: PropTypes.func,
    url: PropTypes.string,
    categories: PropTypes.any,
};

ShareModal.defaultProps = {
    isOpen: false,
    title: '',
    categories: '',
    url: ''
};

export default ShareModal;
