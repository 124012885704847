"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function EventOccurrenceLocationFromJSON(json) {
    return EventOccurrenceLocationFromJSONTyped(json, false);
}
exports.EventOccurrenceLocationFromJSON = EventOccurrenceLocationFromJSON;
function EventOccurrenceLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}
exports.EventOccurrenceLocationFromJSONTyped = EventOccurrenceLocationFromJSONTyped;
function EventOccurrenceLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}
exports.EventOccurrenceLocationToJSON = EventOccurrenceLocationToJSON;
