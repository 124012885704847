"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function InlineResponse200MetaFromJSON(json) {
    return InlineResponse200MetaFromJSONTyped(json, false);
}
exports.InlineResponse200MetaFromJSON = InlineResponse200MetaFromJSON;
function InlineResponse200MetaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'categories': !runtime_1.exists(json, 'categories') ? undefined : (json['categories'].map(_1.InlineResponse200MetaCategoriesFromJSON)),
    };
}
exports.InlineResponse200MetaFromJSONTyped = InlineResponse200MetaFromJSONTyped;
function InlineResponse200MetaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'categories': value.categories === undefined ? undefined : (value.categories.map(_1.InlineResponse200MetaCategoriesToJSON)),
    };
}
exports.InlineResponse200MetaToJSON = InlineResponse200MetaToJSON;
