import React, { useEffect, useState } from 'react';

import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import styles from './OldBrowserBar.module.scss';
import classNames from 'classnames';
import Button from '../Button';
import BrowserDetector from './BrowserDetector';

const OldBrowserBar = () => {
    const [hidden, setHidden] = useState(true);
    const [isOld, setIsOld] = useState(false);

    useEffect(() => {
        if (new BrowserDetector().isSupported()) {
            return;
        }
        setIsOld(true);
        setHidden(false);
    }, []);

    const dismissPopup = () => {
        setHidden(true);
    };

    return (
        isOld && (
            <div
                className={classNames(styles['OldBrowserBar'], {
                    [styles['OldBrowserBar--Hidden']]: hidden,
                })}>
                <div className={styles['OldBrowserBar__Container']}>
                    <div
                        className={styles['OldBrowserBar__Text']}
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('OldBrowser.message'),
                        }}
                    />
                    <div className={styles['OldBrowserBar__Button']}>
                        <Button
                            modifiers={['Button--OldBrowserBar']}
                            text={i18n.t('OldBrowser.accept')}
                            onClick={dismissPopup}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

OldBrowserBar.propTypes = {};

OldBrowserBar.defaultProps = {};

export default OldBrowserBar;
