"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function CardGuideImageFromJSON(json) {
    return CardGuideImageFromJSONTyped(json, false);
}
exports.CardGuideImageFromJSON = CardGuideImageFromJSON;
function CardGuideImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'width': !runtime_1.exists(json, 'width') ? undefined : json['width'],
        'height': !runtime_1.exists(json, 'height') ? undefined : json['height'],
        'renditions': !runtime_1.exists(json, 'renditions') ? undefined : json['renditions'],
        'fileSize': !runtime_1.exists(json, 'file_size') ? undefined : json['file_size'],
        'focal': !runtime_1.exists(json, 'focal') ? undefined : json['focal'],
        'caption': !runtime_1.exists(json, 'caption') ? undefined : json['caption'],
        'alt': !runtime_1.exists(json, 'alt') ? undefined : json['alt'],
    };
}
exports.CardGuideImageFromJSONTyped = CardGuideImageFromJSONTyped;
function CardGuideImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.CardGuideImageToJSON = CardGuideImageToJSON;
