import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import styles from './BasePage.module.scss';
import Footer from '../../components/Footer';
import WagtailUserbar from '../../components/WagtailUserbar';
import Header from '../../components/Header';
import EmergencyMessage from '../../components/EmergencyMessage';
import Breadcrumbs from '../../components/Breadcrumbs';

export default class BasePage extends PureComponent {
    state = {};

    static defaultProps = {
        seo: {},
    };

    static propTypes = {
        children: PropTypes.node,
        seo: PropTypes.shape({
            seoHtmlTitle: PropTypes.string,
            seoMetaDescription: PropTypes.string,
            seoOgTitle: PropTypes.string,
            seoOgDescription: PropTypes.string,
            seoOgUrl: PropTypes.string,
            seoTwitterTitle: PropTypes.string,
            seoMetaRobots: PropTypes.string,
        }),
        wagtailUserbar: PropTypes.shape({
            html: PropTypes.string,
        }),
    };

    render() {
        const {
            children,
            footer,
            seo,
            wagtailUserbar,
            header,
            breadcrumbs,
            siteSetting,
        } = this.props;
        const {
            seoHtmlTitle,
            seoMetaDescription,
            seoOgTitle,
            seoOgDescription,
            seoOgUrl,
            seoOgImage,
            seoOgType,
            seoTwitterTitle,
            seoTwitterDescription,
            seoTwitterUrl,
            seoTwitterImage,
            seoMetaRobots,
            canonicalLink,
        } = seo;

        return (
            <>
                <Head>
                    <title>{seoHtmlTitle}</title>
                    <link rel="icon" href="/favicon.ico" />
                    {!!seoMetaDescription && (
                        <meta name="description" content={seoMetaDescription} />
                    )}
                    {!!seoOgTitle && (
                        <meta property="og:title" content={seoOgTitle} />
                    )}
                    {!!seoOgDescription && (
                        <meta
                            property="og:description"
                            content={seoOgDescription}
                        />
                    )}
                    {!!seoOgUrl && (
                        <meta property="og:url" content={seoOgUrl} />
                    )}
                    {!!seoOgImage && (
                        <meta property="og:image" content={seoOgImage} />
                    )}
                    {!!seoOgType && (
                        <meta property="og:type" content={seoOgType} />
                    )}
                    {!!seoTwitterTitle && (
                        <meta
                            property="twitter:title"
                            content={seoTwitterTitle}
                        />
                    )}
                    {!!seoTwitterDescription && (
                        <meta
                            property="twitter:description"
                            content={seoTwitterDescription}
                        />
                    )}
                    {!!seoTwitterUrl && (
                        <meta property="twitter:url" content={seoTwitterUrl} />
                    )}
                    {!!seoTwitterImage && (
                        <meta
                            property="twitter:image"
                            content={seoTwitterImage}
                        />
                    )}
                    <meta name="robots" content={seoMetaRobots} />
                    {!!canonicalLink && (
                        <link rel="canonical" href={canonicalLink} />
                    )}
                </Head>
                <div className={styles['BasePage']}>
                    <header>
                        <Header {...header} />
                        {!!breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
                        {!!siteSetting?.emergencyMessage && (
                            <EmergencyMessage
                                {...siteSetting.emergencyMessage}
                            />
                        )}
                    </header>
                    <main>{children}</main>
                    {footer && <Footer {...footer} siteSetting={siteSetting} />}
                </div>
                {wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
            </>
        );
    }
}
