import React, { useState, useEffect, useReducer } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {useInView} from "react-intersection-observer";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import Button from '../Button';
import CardGuide from '../CardGuide';
import GuideFilter from '../GuideFilter';
import styles from './GuideList.module.scss';
import { guidesApi, abortGuideRequests } from '../../utils/api';
import { removeSavedId, MY_LIST_KEY_GUIDES } from '../../utils/MyListStorage';
import { dataLayerPush } from '../../utils/datalayer';
import { capitalizeFirstLetter } from '../../utils/helpers';
import isEmpty from '../../utils/isEmpty';

function _filterNull(obj) {
    const ret = {};
    for (const key in obj) {
        if (obj[key] !== null) {
            ret[key] = obj[key];
        }
    }
    return ret;
}

function reducer(state, action) {
    switch (action.type) {
        case 'requestStarted':
            return { ...state, loading: true };
        case 'requestFailed':
            return { ...state, loading: false, error: true };
        case 'guidesReceived':
            const { isFirstPage, hasMoreContent } = action;
            const guides = isFirstPage
                ? action.guides
                : [...state.guides, ...action.guides];
            const nextPage = isFirstPage ? 2 : state.nextPage + 1;
            return {
                ...state,
                guides,
                hasMoreContent,
                nextPage,
                loading: false,
                error: false,
            };
        case 'loadMore':
            return { ...state, page: state.nextPage };
        case 'categoryClicked':
            const { category } = action;
            if (category === 'ALL') {
                return { ...state, selectedCategories: [], page: 1 };
            }

            const _cs = state.selectedCategories;
            let selectedCategories = [];

            if (Array.isArray(category)) {
                for (let i = 0; i < category.length; i++) {
                    const indexInCurrentSelection = _cs.indexOf(category[i]);
                    const shouldAdd = indexInCurrentSelection === -1;

                    selectedCategories = shouldAdd
                        ? [..._cs, category[i].toLowerCase()]
                        : [
                              ..._cs.slice(0, indexInCurrentSelection),
                              ..._cs.slice(indexInCurrentSelection + 1),
                          ];
                }
            } else {
                const indexInCurrentSelection = _cs.indexOf(category);
                const shouldAdd = indexInCurrentSelection === -1;

                selectedCategories = shouldAdd
                    ? [..._cs, category.toLowerCase()]
                    : [
                          ..._cs.slice(0, indexInCurrentSelection),
                          ..._cs.slice(indexInCurrentSelection + 1),
                      ];
            }

            return { ...state, selectedCategories, page: 1 };

        default:
            throw new Error('Unknown action: ' + action.type);
    }
}

const GuideList = ({
    title,
    items,
    filters,
    isMyList,
    showRemoveButtons,
    isEmptyMyList,
    onLocalsPage,
    categories,
}) => {
    const initialState = {
        selectedCategories: [],
        categories: categories,
        hasMoreContent: null,
        relatedEmpty: null,
        page: 1,
        nextPage: 2,
        loading: false,
        error: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchData = () => {
        if (state.loading) {
            abortGuideRequests();
        }

        dispatch({ type: 'requestStarted' });

        /**
         * Request arguments:
         *
         * page?: number;
         * categories: string?
         */
        const args = {
            page: state.page,
            tags: state.selectedCategories.length
                ? state.selectedCategories
                : null,
        };

        if (!isEmpty(initialCategories)) {
            args.categories = initialCategories;
        }
        const reqArgs = _filterNull(args);
        guidesApi
            .listGuidePages(reqArgs)
            .then((data) => {
                dispatch({
                    type: 'guidesReceived',
                    guides: data.results,
                    hasMoreContent: !!data.next,
                    isFirstPage: !data.previous,
                });
            })
            .catch((e) => {
                // Not an error, we do this every time filter changes while querying the api
                if (e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'requestFailed' });
                console.error(e);
            });
    };

    const MIN_VISIBLE_CARDS = 8;
    const MIN_VISIBLE_FILTERS = 5;
    const [initialCategories, setInitialCategories] = useState(
        categories.map((category) => category.slug)
    );
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [filterArray, setFilterArray] = useState([]);
    const [visibleFilters, setVisibleFilters] = useState([]);
    const [visibleItems, setVisibleItems] = useState(MIN_VISIBLE_CARDS);
    const [filteredCards, setFilteredCards] = useState(items);
    const isFullWidth = Boolean(items.length === 1) && !isMyList;

    useEffect(() => {
        if (!isEmpty(items)) {
            dispatch({
                type: 'guidesReceived',
                guides: items,
                hasMoreContent: false,
                isFirstPage: true,
            });
        }
        if (filters) {
            setFilterArray(filters);
            setVisibleFilters(filters.slice(0, MIN_VISIBLE_FILTERS));
        }
        setVisibleItems(MIN_VISIBLE_CARDS);
    }, [items, filters, isMyList, onLocalsPage]);

    useEffect(() => {
        if (isEmpty(items)) {
            fetchData();
        }
    }, [state.selectedCategories, state.page, items]);

    const handleLoadMore = () => {
        dataLayerPush({ event: 'button' });
        setVisibleItems(visibleItems + 8);
        dispatch({ type: 'loadMore' });
    };

    const removeItemsFromList = (i) => {
        removeSavedId(MY_LIST_KEY_GUIDES, filteredCards[i].id);
        let cardList = filteredCards;
        cardList.splice(i, 1);
        setFilteredCards(cardList);
        setVisibleItems(cardList.length);
    };

    const handleFilters = (i) => {
        let filters = [...filterArray];
        const { slug, text, active } = filterArray[i];

        filters[i].active = !active;
        setFilterArray(filters);

        if (filters[i].active) {
            dataLayerPush({
                event: 'menu',
                eventCategory: 'Menu',
                eventAction: '3',
                eventLabel: capitalizeFirstLetter(text),
            });
        }

        dispatch({
            type: 'categoryClicked',
            category: slug,
        });
    };

    const toggleFilters = () => {
        if (showAllFilters) {
            setVisibleFilters(filterArray.slice(0, MIN_VISIBLE_FILTERS));
        } else {
            setVisibleFilters(filterArray);
        }
        setShowAllFilters(!showAllFilters);
    };

    const {ref, inView} = useInView({
        threshold: .2,
        triggerOnce: true
    });

    const showTitle =
        (Boolean(filterArray.length) && !isMyList) || isEmptyMyList || title;

    return (
        <div
            className={classNames(styles['GuideList'], {
                [styles['GuideList--MyList']]: isMyList || onLocalsPage,
                [styles['GuideList--isVisible']]: inView,
            })}>
            {showTitle && (
                <h2
                    className={classNames(styles['GuideList__Title'], {
                        [styles['GuideList__Title--Left']]:
                            !Boolean(visibleFilters.length) &&
                            !isMyList &&
                            !isEmptyMyList,
                        [styles['GuideList__Title--EmptyMyList']]:
                            !Boolean(visibleFilters.length) && isEmptyMyList,
                    })}>
                    {title && !isEmptyMyList
                        ? title
                        : i18n.t('Guides.emptyMyList')}
                </h2>
            )}

            {Boolean(visibleFilters.length) && (
                <div className={styles['GuideList__FilterContainer']}>
                    {visibleFilters.map((filter, i) => {
                        return (
                            <GuideFilter
                                key={i}
                                {...filter}
                                active={filter.active}
                                onClick={(e) => {
                                    handleFilters(i);
                                }}
                            />
                        );
                    })}

                    {filterArray.length > MIN_VISIBLE_FILTERS && (
                        <GuideFilter
                            color="white"
                            text={
                                visibleFilters.length > MIN_VISIBLE_FILTERS
                                    ? `- ${i18n.t('generic.less')}`
                                    : `+ ${i18n.t('generic.more')}`
                            }
                            onClick={toggleFilters}
                        />
                    )}
                </div>
            )}
            <div ref={ref} />
            <div
                className={classNames(styles['GuideList__CardsContainer'], {
                    [styles['GuideList__CardsContainer--MyList']]:
                        isMyList || onLocalsPage,
                    [styles[
                        'GuideList__CardsContainer--FullWidth'
                    ]]: isFullWidth,
                })}>
                {state.guides && (
                    <TransitionGroup
                        className={styles['GuideList__Cards']}
                        enter={true}
                        exit={true}>
                        {state.guides.map((card, i) => {
                            return (
                                i < visibleItems && (
                                    <CSSTransition
                                        key={card.id}
                                        transitionAppear={true}
                                        classNames={{
                                            enter:
                                                styles[
                                                    'GuideList__animation-enter'
                                                ],
                                            enterActive:
                                                styles[
                                                    'GuideList__animation-enter-active'
                                                ],
                                            exit:
                                                styles[
                                                    'GuideList__animation-exit'
                                                ],
                                            exitActive:
                                                styles[
                                                    'GuideList__animation-exit-active'
                                                ],
                                        }}
                                        timeout={500}>
                                        <CardGuide
                                            {...card}
                                            modifiers={[
                                                styles['GuideList__Card'],
                                            ]}
                                            showRemoveButton={showRemoveButtons}
                                            fullWidth={isFullWidth}
                                            onLocalsPage={onLocalsPage}
                                            inMyList={isMyList}
                                            onClick={
                                                isMyList
                                                    ? () =>
                                                          removeItemsFromList(i)
                                                    : null
                                            }
                                        />
                                    </CSSTransition>
                                )
                            );
                        })}
                    </TransitionGroup>
                )}
                {state.error && (
                    <div className={styles['GuideList__Error']}>
                        {' '}
                        {i18n.t('Event.loadingError')}{' '}
                    </div>
                )}

                {state.guides && state.guides.length < 1 && (
                    <div className={styles['GuideList__NoResults']}>
                        {i18n.t('Event.noResults')}
                    </div>
                )}

                <div
                    style={{
                        visibility: state.loading ? 'visible' : 'hidden',
                    }}
                    className={styles['GuideList__LoaderContainer']}>
                    <div className={styles['GuideList__Loader']} />
                    <div className={styles['GuideList__Loader']} />
                </div>

                {state.hasMoreContent && !isMyList && (
                    <Button
                        modifiers={[styles['GuideList__Button']]}
                        type="secondaryInvert"
                        text={i18n.t('Event.loadMore')}
                        onClick={handleLoadMore}
                    />
                )}
            </div>
        </div>
    );
};

GuideList.propTypes = {
    filters: PropTypes.array,
    items: PropTypes.array,
    relatedItems: PropTypes.array,
    title: PropTypes.string,
    isMyList: PropTypes.bool,
    showRemoveButtons: PropTypes.bool,
    isEmptyMyList: PropTypes.bool,
    onLocalsPage: PropTypes.bool,
    categories: PropTypes.array,
};

GuideList.defaultProps = {
    filters: null,
    items: [],
    relatedItems: [],
    title: null,
    isMyList: false,
    showRemoveButtons: false,
    isEmptyMyList: false,
    onLocalsPage: false,
    categories: [],
};

export default GuideList;
