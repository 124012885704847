import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';
import Button from '../Button';
import styles from './Cookiebar.module.scss';

const Cookiebar = ({ content }) => {
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        setHidden(getCookie('hide_cookiebar') || false);
    }, []);

    const dismissPopup = () => {
        setCookie('hide_cookiebar', true);
        setHidden(true);
    };

    return (
        content && (
            <div
                className={classNames(styles['Cookiebar'], {
                    [styles['Cookiebar--Hidden']]: hidden,
                })}>
                <div className={styles['Cookiebar__Container']}>
                    <div
                        className={styles['Cookiebar__Text']}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                    <div className={styles['Cookiebar__Button']}>
                        <Button
                            modifiers={['Button--Cookiebar']}
                            text={i18n.t('cookiebar.accept')}
                            onClick={dismissPopup}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

const setCookie = (cname, cvalue) => {
    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const getCookie = (name) => {
    if (typeof window === 'undefined') {
        return '';
    }

    name = name + '=';
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let part = cookie[i];
        while (part.charAt(0) === ' ') {
            part = part.substring(1);
        }
        if (part.indexOf(name) === 0) {
            return part.substring(name.length, part.length);
        }
    }
    return '';
};

Cookiebar.propTypes = {
    content: PropTypes.string,
};

Cookiebar.defaultProps = {
    content: '',
};

export default Cookiebar;
