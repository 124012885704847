"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require("./");
function CardGuideFromJSON(json) {
    return CardGuideFromJSONTyped(json, false);
}
exports.CardGuideFromJSON = CardGuideFromJSON;
function CardGuideFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'href': json['href'],
        'image': _1.CardGuideImageFromJSON(json['image']),
        'category': json['category'],
        'byline': _1.CardGuideBylineFromJSON(json['byline']),
    };
}
exports.CardGuideFromJSONTyped = CardGuideFromJSONTyped;
function CardGuideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'href': value.href,
        'image': _1.CardGuideImageToJSON(value.image),
        'category': value.category,
        'byline': _1.CardGuideBylineToJSON(value.byline),
    };
}
exports.CardGuideToJSON = CardGuideToJSON;
