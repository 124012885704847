import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import styles from './LabeledIcon.module.scss';
import classNames from 'classnames';

const LabeledIcon = ({
    type,
    color,
    label,
    extraLabel,
    href,
    onClick,
    isVertical,
    isHorizontal,
    fontSize,
    labelMargin,
    extraLabelMod,
    modifiers,
}) => {
    return (
        <div
            className={
                modifiers.map((x) => x + ' ') + ' ' + styles['LabeledIcon']
            }>
            {href && (
                <a
                    className={classNames(
                        styles['LabeledIcon__Link'],
                        {
                            [styles['LabeledIcon__Link--IsVertical']]:
                                isVertical === true,
                        },
                        {
                            [styles['LabeledIcon__Link--IsHorizontal']]:
                                isHorizontal === true,
                        }
                    )}
                    onClick={onClick}
                    href={href}
                    title={label}>
                    <Icon
                        type={type}
                        color={color}
                        modifiers={[styles['LabeledIcon__LinkIcon']]}
                        size="large"
                    />

                    <span
                        style={{ color }}
                        className={classNames(
                            labelMargin,
                            styles['LabeledIcon__LinkLabel']
                        )}>
                        {fontSize ? (
                            <span className={fontSizeAndLabelMargin}>
                                {label}
                            </span>
                        ) : (
                            label
                        )}
                    </span>
                    {extraLabel && (
                        <span
                            style={{ color }}
                            className={classNames(
                                extraLabelMod,
                                styles['LabeledIcon__LinkLabel']
                            )}>
                            {extraLabel}
                        </span>
                    )}
                </a>
            )}

            {!href && onClick && (
                <button
                    className={classNames(
                        styles['LabeledIcon__Link'],
                        {
                            [styles['LabeledIcon__Link--IsVertical']]:
                                isVertical === true,
                        },
                        {
                            [styles['LabeledIcon__Link--IsHorizontal']]:
                                isHorizontal === true,
                        }
                    )}
                    onClick={onClick}
                    title={label}>
                    <Icon
                        type={type}
                        color={color}
                        modifiers={[styles['LabeledIcon__LinkIcon']]}
                        size="large"
                    />

                    <span
                        style={{ color }}
                        className={classNames(
                            labelMargin,
                            styles['LabeledIcon__LinkLabel']
                        )}>
                        {fontSize ? (
                            <span className={fontSizeAndLabelMargin}>
                                {label}
                            </span>
                        ) : (
                            label
                        )}
                    </span>
                    {extraLabel && (
                        <span
                            style={{ color }}
                            className={classNames(
                                extraLabelMod,
                                styles['LabeledIcon__LinkLabel']
                            )}>
                            {extraLabel}
                        </span>
                    )}
                </button>
            )}
        </div>
    );
};

LabeledIcon.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    extraLabel: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
    isVertical: PropTypes.bool,
    isHorizontal: PropTypes.bool,
    fontSize: PropTypes.string,
    labelMargin: PropTypes.string,
    extraLabelMod: PropTypes.array,
    modifiers: PropTypes.array,
};

LabeledIcon.defaultProps = {
    type: 'close',
    color: 'white',
    label: '',
    extraLabel: '',
    onClick: () => {},
    href: '',
    isVertical: false,
    isHorizontal: false,
    fontSize: '',
    labelMargin: '',
    extraLabelMod: [],
    modifiers: [],
};

export default LabeledIcon;
