import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import i18n from '../../i18n';
import styles from './VideoModule.module.scss';
import Icon from '../Icon';
import classNames from 'classnames';

const VideoModule = ({ autoplay, image, videoUrl, title, preamble }) => {
    const [play, setPlay] = useState(autoplay);
    const [videoStarted, setVideoStarted] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handlePlayPause = () => {
        setPlay(!play);
    };

    const handleOpenModal = () => {
        document.body.style.overflow = 'hidden';
        setPlay(false);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        document.body.style.overflow = 'visible';
        setPlay(autoplay);
        setShowModal(false);
    };

    if (!videoUrl) {
        return null;
    }

    return (
        <div className={styles['VideoModule']}>
            <figure className={styles['VideoModule__Container']}>
                <div className={styles['VideoModule__Video']}>
                    <Vimeo
                        video={videoUrl}
                        background={false}
                        showTitle={false}
                        showByline={false}
                        paused={!play}
                        volume={0}
                        muted
                        controls={false}
                        loop
                        responsive
                        autoplay={autoplay}
                        onPlay={() => setVideoStarted(true)}
                        onReady={() => setVideoStarted(true)}
                        onEnd={() => setVideoStarted(false)}
                    />
                </div>
                {image?.renditions?.xlarge && (
                    <div className={styles['VideoModule__ImageWrapper']}>
                        <img
                            className={classNames(
                                styles['VideoModule__Image'],
                                {
                                    [styles[
                                        'VideoModule__Image--VideoLoaded'
                                    ]]: videoStarted,
                                }
                            )}
                            sizes="100vw"
                            srcSet={`${image.renditions.large.src} 1646w,
                                 ${image.renditions.medium.src} 1200w,
                                ${image.renditions.small.src} 600w`}
                            src={image.renditions.medium.src}
                            alt=""
                            style={{
                                objectPosition:
                                    image.focal.x + ' ' + image.focal.y,
                            }}
                        />
                    </div>
                )}
            </figure>

            <div className={styles['VideoModule__TextWrapper']}>
                <section className={styles['VideoModule__InnerWrapper']}>
                    <div className={styles['VideoModule__Text']}>
                        <h1 className={styles['VideoModule__Title']}>
                            {title}
                        </h1>

                        <p className={styles['VideoModule__Preamble']}>
                            <span className="sr-only">
                                {i18n.t('generic.preamble')} :
                            </span>
                            {preamble}
                        </p>
                    </div>
                </section>

                <div className={styles['VideoModule__ButtonsWrapper']}>
                    <VideoButton
                        onClick={handleOpenModal}
                        play={false}
                        text={i18n.t('VideoModule.watchMovie')}
                    />
                    <VideoButton onClick={handlePlayPause} play={play} />
                </div>
            </div>
            {showModal && (
                <VideoModal
                    handleCloseModal={handleCloseModal}
                    videoUrl={videoUrl}
                />
            )}
        </div>
    );
};

VideoModule.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    autoplay: PropTypes.bool,
    image: PropTypes.object,
};

VideoModule.defaultProps = {
    videoUrl: '',
    autoplay: true,
    image: {},
};

const VideoModal = ({ handleCloseModal, videoUrl }) => (
    <div
        className={classNames(styles['VideoModule__Modal'], {
            [styles['VideoModule__Modal--Show']]: true,
        })}>
        <div className={styles['VideoModule__ModalWrapper']}>
            <div className={styles['VideoModule__ModalContent']}>
                <button
                    aria-label={i18n.t('Modal.close')}
                    label={i18n.t('Modal.close')}
                    className={styles['VideoModule__CloseModal']}
                    onClick={handleCloseModal}>
                    <div className={styles['VideoModule__CloseIconWrapper']}>
                        <Icon type="close" color="black800" size="medium" />
                    </div>
                </button>

                <div className={styles['VideoModule__ModalPlayer']}>
                    <Vimeo
                        video={videoUrl}
                        responsive={true}
                        background={false}
                        showTitle={true}
                        showByline={true}
                        controls={true}
                    />
                </div>
            </div>
        </div>
    </div>
);

const VideoButton = ({ onClick, play, text = null }) => {
    return (
        <button
            className={styles['VideoModule__VideoButton']}
            aria-label={
                play ? i18n.t('VideoModule.pause') : i18n.t('VideoModule.play')
            }
            label={
                play ? i18n.t('VideoModule.pause') : i18n.t('VideoModule.play')
            }
            onClick={onClick}>
            <div className={styles['VideoModule__IconBackground']}>
                <div className={styles['VideoModule__IconWrapper']}>
                    <Icon
                        type={play ? 'pause' : 'play'}
                        color="black800"
                        size="xs"
                    />
                </div>
            </div>

            {text && (
                <p className={styles['VideoModule__ButtonText']}>{text}</p>
            )}

            {!text && (
                <p className={styles['VideoModule__ButtonText']}>
                    {play ? i18n.t('VideoModule.pause') : i18n.t('VideoModule.play')}
                </p>
            )}
        </button>
    );
};

export default VideoModule;
