"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function UploadedImageFromJSON(json) {
    return UploadedImageFromJSONTyped(json, false);
}
exports.UploadedImageFromJSON = UploadedImageFromJSON;
function UploadedImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pk': !runtime_1.exists(json, 'pk') ? undefined : json['pk'],
        'title': json['title'],
        'file': json['file'],
        'credit': !runtime_1.exists(json, 'credit') ? undefined : json['credit'],
    };
}
exports.UploadedImageFromJSONTyped = UploadedImageFromJSONTyped;
function UploadedImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'file': value.file,
        'credit': value.credit,
    };
}
exports.UploadedImageToJSON = UploadedImageToJSON;
