"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function EventOccurrenceFromJSON(json) {
    return EventOccurrenceFromJSONTyped(json, false);
}
exports.EventOccurrenceFromJSON = EventOccurrenceFromJSON;
function EventOccurrenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'externalWebsiteUrl': json['external_website_url'],
        'callToActionUrl': json['call_to_action_url'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'address': json['address'],
        'venueName': json['venue_name'],
        'zipCode': json['zip_code'],
        'city': json['city'],
        'location': _1.EventOccurrenceLocationFromJSON(json['location']),
        'createdAt': (new Date(json['created_at'])),
        'modifiedAt': (new Date(json['modified_at'])),
        'date': (new Date(json['date'])),
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'startTime': json['start_time'],
        'endTime': json['end_time'],
        'categories': (json['categories'].map(_1.EventOccurrenceCategoriesFromJSON)),
        'featuredImage': !runtime_1.exists(json, 'featured_image') ? undefined : json['featured_image'],
    };
}
exports.EventOccurrenceFromJSONTyped = EventOccurrenceFromJSONTyped;
function EventOccurrenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'external_website_url': value.externalWebsiteUrl,
        'call_to_action_url': value.callToActionUrl,
        'address': value.address,
        'venue_name': value.venueName,
        'zip_code': value.zipCode,
        'city': value.city,
        'location': _1.EventOccurrenceLocationToJSON(value.location),
        'created_at': (value.createdAt.toISOString()),
        'modified_at': (value.modifiedAt.toISOString()),
        'date': (value.date.toISOString().substr(0, 10)),
        'start_date': (value.startDate.toISOString().substr(0, 10)),
        'end_date': (value.endDate.toISOString().substr(0, 10)),
        'start_time': value.startTime,
        'end_time': value.endTime,
        'categories': (value.categories.map(_1.EventOccurrenceCategoriesToJSON)),
    };
}
exports.EventOccurrenceToJSON = EventOccurrenceToJSON;
