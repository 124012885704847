"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function EventOccurrenceCategoriesFromJSON(json) {
    return EventOccurrenceCategoriesFromJSONTyped(json, false);
}
exports.EventOccurrenceCategoriesFromJSON = EventOccurrenceCategoriesFromJSON;
function EventOccurrenceCategoriesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'slug': json['slug'],
    };
}
exports.EventOccurrenceCategoriesFromJSONTyped = EventOccurrenceCategoriesFromJSONTyped;
function EventOccurrenceCategoriesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'slug': value.slug,
    };
}
exports.EventOccurrenceCategoriesToJSON = EventOccurrenceCategoriesToJSON;
