import React from 'react';

import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

const Modal = ({ children }) => {
    if (!children) {
        return null;
    }

    return (
        <div className={styles['Modal']} onClick={(e) => e.stopPropagation()}>
            <div className={styles['Modal__ChildrenWrapper']}>{children}</div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Modal.defaultProps = {};

export default Modal;
