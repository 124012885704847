"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function EventSubmissionFromJSON(json) {
    return EventSubmissionFromJSONTyped(json, false);
}
exports.EventSubmissionFromJSON = EventSubmissionFromJSON;
function EventSubmissionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'titleEn': json['title_en'],
        'titleSv': !runtime_1.exists(json, 'title_sv') ? undefined : json['title_sv'],
        'descriptionEn': !runtime_1.exists(json, 'description_en') ? undefined : json['description_en'],
        'descriptionSv': !runtime_1.exists(json, 'description_sv') ? undefined : json['description_sv'],
        'externalWebsiteUrl': !runtime_1.exists(json, 'external_website_url') ? undefined : json['external_website_url'],
        'callToActionUrl': !runtime_1.exists(json, 'call_to_action_url') ? undefined : json['call_to_action_url'],
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'venueName': !runtime_1.exists(json, 'venue_name') ? undefined : json['venue_name'],
        'zipCode': !runtime_1.exists(json, 'zip_code') ? undefined : json['zip_code'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'categories': json['categories'],
        'featuredImage': !runtime_1.exists(json, 'featured_image') ? undefined : json['featured_image'],
        'dateAdmin': (json['date_admin'].map(_1.EventSubmissionDateAdminFromJSON)),
        'submittedByEmail': !runtime_1.exists(json, 'submitted_by_email') ? undefined : json['submitted_by_email'],
    };
}
exports.EventSubmissionFromJSONTyped = EventSubmissionFromJSONTyped;
function EventSubmissionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title_en': value.titleEn,
        'title_sv': value.titleSv,
        'description_en': value.descriptionEn,
        'description_sv': value.descriptionSv,
        'external_website_url': value.externalWebsiteUrl,
        'call_to_action_url': value.callToActionUrl,
        'address': value.address,
        'venue_name': value.venueName,
        'zip_code': value.zipCode,
        'city': value.city,
        'categories': value.categories,
        'featured_image': value.featuredImage,
        'date_admin': (value.dateAdmin.map(_1.EventSubmissionDateAdminToJSON)),
        'submitted_by_email': value.submittedByEmail,
    };
}
exports.EventSubmissionToJSON = EventSubmissionToJSON;
