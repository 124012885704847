import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ButtonRound.module.scss';
import { ucFirst } from '../../utils/caseconverters';
import Icon from '../../components/Icon';
import { allIcons } from '../Icon/Icon';

const ButtonRound = ({ type, href, onClick, modalSize, iconHoverColor }) => {
    const AnchorOrButton = href ?  'a' : 'button'
    return (<>
        {type && (
            <AnchorOrButton href={href} onClick={onClick} className={styles['ButtonRound']}>
                <span className="sr-only">{ucFirst(type)}</span>

                <Icon
                    type={type}
                    color="white"
                    size="large"
                    round={true}
                    modalSize={modalSize}
                    hoverColor={iconHoverColor}
                />
            </AnchorOrButton>
        )}
    </>)
};

ButtonRound.propTypes = {
    href: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(allIcons)).isRequired,
    modalSize: PropTypes.bool,
    iconHoverColor: PropTypes.oneOf(['white', 'black800', 'pink']),
};

ButtonRound.defaultProps = {
    modalSize: false,
    iconHoverColor: null,
    href: '',
    onClick: () => {},
    type: 'facebook',
};

export default ButtonRound;
