export const dataLayerPush = (layer) => {
    if (typeof window === 'undefined') {
        return;
    }

    window._mtm = window._mtm || [];
    window._mtm.push(layer);
};

export function trackEvent(event, category, action, label, dimensions = {}) {
    dataLayerPush({
        event,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        ...dimensions,
    });
}
