const capitalizeFirstLetter = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)
}

const isLinkExternal = (link) => link.includes('://');


export {
    isLinkExternal,
    capitalizeFirstLetter
}
