import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './CollaborationText.module.scss';

const CollaborationText = ({ html }) => {
    return (
        <>
            {!!html && (
                <div
                    className={styles['CollaborationText']}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            )}
        </>
    );
};

CollaborationText.propTypes = { html: PropTypes.string };

CollaborationText.defaultProps = { html: '' };

export default CollaborationText;
