import React, {useEffect, useState, useRef} from 'react';

import PropTypes from 'prop-types';
import VideoModule from '../VideoModule';
import i18n from '../../i18n';
import styles from './Hero.module.scss';
import classNames from 'classnames';

const Hero = ({ title, subTitle, image, video, preamble }) => {
    const [isVisible, setVisible] = React.useState(false);
    const [isLoaded, setLoaded] = React.useState(false);
    const imgRef = useRef();
    useEffect(() => {
        if (imgRef.current && imgRef.current.complete) {
            setLoaded(true);
        }
    }, []);

    React.useEffect(() => {
        setVisible(true);
    }, []);


    return (
        <div
            className={classNames(
                styles['Hero'],
                {
                    [styles['Hero--Video']]: video,
                    [styles['Hero--isVisible']]: isVisible,
                }
            )}>
            <div className={styles['Hero__Container']}>
                {!video && image && image.renditions && (
                    <figure className={styles['Hero__BackgroundContainer']}>
                        <img
                            className={classNames(
                                styles['Hero__BackgroundImage'],
                                {
                                    [styles['Hero__BackgroundImage--isLoaded']]: isLoaded,
                                }
                            )}
                            ref={imgRef}
                            sizes="100vw"
                            srcSet={`${image.renditions.xlarge.src} 1646w,
                                 ${image.renditions.large.src} 1200w,
                                ${image.renditions.medium.src} 600w`}
                            src={image.renditions.large.src}
                            alt={image.alt}
                            style={{
                                objectPosition:
                                    image.focal.x + ' ' + image.focal.y,
                                    opacity: 0,
                            }}
                            onLoad={(event) => setLoaded(true)}
                        />
                        {image.caption && (
                            <figcaption className={styles['Hero__Caption']}>
                                {i18n.t('generic.photo')} {image.caption}
                            </figcaption>
                        )}
                    </figure>
                )}
                {video && (
                    <div className={styles['Hero__VideoContainer']}>
                        <VideoModule
                            {...video}
                            showControls="false"
                            title={title}
                            preamble={preamble}
                            autoplay={true}
                        />
                    </div>
                )}

                {title && !video && (
                    <div className={styles['Hero__TextContainer']}>
                        <section className={styles['Hero__InnerContainer']}>
                            <h1 className={styles['Hero__Title']}>{title}</h1>

                            {!!subTitle && <h2 className={styles['Hero__Preamble']}>{subTitle}</h2>}

                            <span className="sr-only">
                                {i18n.t('generic.preamble')} :
                            </span>

                            <p className={styles['Hero__Preamble']}>
                                {preamble}
                            </p>
                        </section>
                    </div>
                )}
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    image: PropTypes.object,
    video: PropTypes.object,
};

Hero.defaultProps = {
    title: '',
    preamble: '',
    image: null,
    video: null,
};

export default Hero;
