import React from 'react';

import classNames from 'classnames';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './Byline.module.scss';

import Picture from '../Picture/Picture';
import { ucFirst } from '../../utils/caseconverters';

const Byline = ({ size, image, author, bylineColor, shadow, modifiers }) => {
    return (
        <div className={styles['Byline'] + modifiers.map((x) => ' ' + x)}>
            {image && image.renditions && (
                <div className={styles['Byline__Image']}>
                    <Picture size={size} image={image} shadow={shadow} />
                </div>
            )}

            {author && (author.name || author.title) && (
                <div
                    className={classNames(
                        'Byline--Info',
                        styles['Byline__Info'],
                        styles['Byline__Info--' + ucFirst(bylineColor)]
                    )}>
                    {author && author.name && (
                        <strong className={styles['Byline__Name']}>
                            {`${i18n.t('generic.by')} ${author.name}`}
                        </strong>
                    )}
                    <p
                        className={
                            'Byline__Title ' + styles['Byline__Title']
                        }>
                        {!!author?.isLocal ? `${i18n.t('Local.label')}` : author.title}
                    </p>
                </div>
            )}
        </div>
    );
};

Byline.propTypes = {
    image: PropTypes.object,
    author: PropTypes.object,
    shadow: PropTypes.bool,
    bylineColor: PropTypes.oneOf(['light', 'dark']),
    modifiers: PropTypes.array,
};

Byline.defaultProps = {
    image: null,
    author: null,
    bylineColor: 'light',
    shadow: false,
    modifiers: [],
};

export default Byline;
