"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function CardGuideBylineAuthorFromJSON(json) {
    return CardGuideBylineAuthorFromJSONTyped(json, false);
}
exports.CardGuideBylineAuthorFromJSON = CardGuideBylineAuthorFromJSON;
function CardGuideBylineAuthorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'image': !runtime_1.exists(json, 'image') ? undefined : json['image'],
        'href': !runtime_1.exists(json, 'href') ? undefined : json['href'],
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
        'isLocal': !runtime_1.exists(json, 'is_local') ? undefined : json['is_local'],
    };
}
exports.CardGuideBylineAuthorFromJSONTyped = CardGuideBylineAuthorFromJSONTyped;
function CardGuideBylineAuthorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.CardGuideBylineAuthorToJSON = CardGuideBylineAuthorToJSON;
