import React from 'react';

// import i18n from '../../i18n';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import styles from './Picture.module.scss';

const Picture = ({ size, image, classNameBorderRadius, modifier }) => {
    return (
        <>
            {size && image && image.renditions && (
                <div
                    className={classNames(
                        styles['Picture'],
                        styles['Picture--' + ucFirst(size)]
                    )}>
                    {image && (
                        <LazyLoad once>
                            <div
                                className={classNames(
                                    classNameBorderRadius,
                                    styles['Picture__Picture'],
                                    styles[
                                        'Picture__Picture--' + ucFirst(size)
                                    ],
                                    styles['Picture__Picture--' + modifier]
                                )}
                                style={{
                                    backgroundImage: `url(${image.renditions[size].src})`,
                                    backgroundPosition: `${image.focal.x} ${image.focal.y}`,
                                }}></div>
                        </LazyLoad>
                    )}
                </div>
            )}
        </>
    );
};

Picture.propTypes = {
    image: PropTypes.shape({
        renditions: PropTypes.object.isRequired,
    }).isRequired,
    shadow: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    classNameBorderRadius: PropTypes.string,
};

Picture.defaultProps = {
    image: null,
    shadow: false,
    size: null,
    classNameBorderRadius: '',
};

export default Picture;
