"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function InlineResponse2001FromJSON(json) {
    return InlineResponse2001FromJSONTyped(json, false);
}
exports.InlineResponse2001FromJSON = InlineResponse2001FromJSON;
function InlineResponse2001FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': !runtime_1.exists(json, 'count') ? undefined : json['count'],
        'next': !runtime_1.exists(json, 'next') ? undefined : json['next'],
        'previous': !runtime_1.exists(json, 'previous') ? undefined : json['previous'],
        'results': !runtime_1.exists(json, 'results') ? undefined : (json['results'].map(_1.CardGuideFromJSON)),
    };
}
exports.InlineResponse2001FromJSONTyped = InlineResponse2001FromJSONTyped;
function InlineResponse2001ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : (value.results.map(_1.CardGuideToJSON)),
    };
}
exports.InlineResponse2001ToJSON = InlineResponse2001ToJSON;
