import React from 'react';

import PropTypes from 'prop-types';
import styles from './InfoBox.module.scss';
import i18n from '../../i18n';
import Icon from '../Icon';
import isEmpty from "../../utils/isEmpty";

const InfoBox = ({
                     title,
                     text,
                     address,
                     telephone,
                     href,
                     label,
                     bookingHref,
                     bookingLabel,
                 }) => {
    if (isEmpty(title) &&
        isEmpty(text) &&
        isEmpty(address) &&
        isEmpty(telephone) &&
        isEmpty(href) &&
        isEmpty(label) &&
        isEmpty(address) &&
        isEmpty(bookingHref)
    ) {
        return null;
    }

    let encodedHref = '';
    let styledAddress = '';
    let cleanTelephone = '';

    if (text) {
        text = text.split('</a>').join('<span></span></a>');
    }

    if (address) {
        encodedHref = encodeURIComponent(address);

        styledAddress = address.split(',').map((item, i) => {
            return <p key={i}>{item}</p>;
        });
    }

    if (telephone) {
        cleanTelephone = telephone.replace(/[^\d]/g, '');
    }

    return (
        <section className={styles['InfoBox']}>
            {title && <h4 className={styles['InfoBox__Title']}>{title}</h4>}
            {text && (
                <div className={styles['InfoBox__TextWrapper']}>
                    <div
                        className={styles['InfoBox__Text']}
                        dangerouslySetInnerHTML={{__html: text}}
                    />
                </div>
            )}
            {address && address.length > 2 && (
                <div className={styles['InfoBox__AddressWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <div className="InfoBox__AddressIconWrapper">
                            <Icon type="place" size="large" color="black800"/>
                        </div>
                    </div>
                    <div className={styles['InfoBox__Address']}>
                        {styledAddress}
                    </div>
                </div>
            )}
            {telephone && (
                <div className={styles['InfoBox__TelephoneWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="phone" size="large" color="black800"/>
                    </div>
                    <a
                        className={styles['InfoBox__Link']}
                        href={'tel:' + cleanTelephone}>
                        {telephone}
                    </a>
                </div>
            )}

            {address && address.length > 2 && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800"/>
                    </div>
                    <a
                        className={styles['InfoBox__Link']}
                        href={
                            'https://www.google.com/maps/place/' + encodedHref
                        }>
                        {i18n.t('EventPage.mapsLink')}
                    </a>
                </div>
            )}
            {href && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800"/>
                    </div>
                    <a className={styles['InfoBox__Link']} href={href}>
                        {label || href}
                    </a>
                </div>
            )}
            {!!bookingHref && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800"/>
                    </div>
                    <a className={styles['InfoBox__Link']} href={bookingHref}>
                        {bookingLabel || bookingHref}
                    </a>
                </div>
            )}
        </section>
    );
};

InfoBox.propTypes = {
    address: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    telephone: PropTypes.string,
};

InfoBox.defaultProps = {
    address: '',
    text: '',
    title: '',
    href: '',
    label: '',
    telephone: '',
};

export default InfoBox;
