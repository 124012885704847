import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import styles from './Header.module.scss';

import LabeledIcon from '../LabeledIcon';
import Icon from '../Icon';
import DropdownLinks from '../DropdownLinks';
import { countSavedItems } from '../../utils/MyListStorage';
import Cookiebar from '../Cookiebar';
import OldBrowserBar from '../OldBrowserBar';
import { dataLayerPush } from '../../utils/datalayer';
import { capitalizeFirstLetter } from '../../utils/helpers';

const MainNavItem = ({ href, label, active }) => (
    <li className={styles['Header__MainNavItem']}>
        <a
            className={classNames(styles['Header__MainNavItemLink'], {
                [styles['Header__MainNavItemLink--Active']]: active,
            })}
            onClick={(e) =>
                dataLayerPush({
                    event: 'menu',
                    eventCategory: 'Menu',
                    eventAction: '1',
                    eventLabel: capitalizeFirstLetter(label),
                })
            }
            href={href}>
            <span className={styles['Header__MainNavItemLabel']}>{label}</span>
            {active && (
                <span className="sr-only">({i18n.t('generic.current')})</span>
            )}
            <span className={styles['Header__MainNavItem--Hover']}></span>
        </a>
    </li>
);

MainNavItem.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
};

const Header = ({
    mainNav,
    navActivePages,
    languages,
    search,
    bookmark,
    cookieBar,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [myListCounter, setMyListCounter] = useState(0);

    useEffect(() => {
        setMyListCounter(countSavedItems());
        window.addEventListener('myListUpdated', () =>
            setMyListCounter(countSavedItems())
        );
    }, []);

    return (
        <>
            {!!cookieBar?.content && <Cookiebar content={cookieBar.content} />}
            <OldBrowserBar />
            <nav className={styles['Header']}>
                <div className={styles['Header__Inner']}>
                    <a className={styles['Header__Brand']} href="/">
                        <span className="sr-only">Home</span>
                        <img
                            src="/assets/img/logo-white.svg"
                            alt="Logo: Stockholm - capital of Scandinavia"
                            className={styles['Header__Logo']}
                            width="151"
                            height="36.03"
                        />
                    </a>

                    <div
                        id="navbarContent"
                        className={styles['Header__Content']}>
                        <div
                            className={classNames(
                                styles['Header__ExpandedMenu'],
                                {
                                    [styles['Header__ExpandedMenu--Show']]:
                                        expanded,
                                }
                            )}>
                            <div className={styles['Header__MobileHeader']}>
                                <div
                                    className={
                                        styles['Header__MobileHeaderHeadline']
                                    }>
                                    Menu
                                </div>
                                {!!languages && !!languages.length && (
                                    <div
                                        className={
                                            styles['Header__LanguageMobile']
                                        }>
                                        <DropdownLinks
                                            items={languages}
                                            modifier="mobile"
                                        />
                                    </div>
                                )}
                                <div
                                    className={
                                        styles['Header__MobileHeader--Close']
                                    }
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}>
                                    <Icon
                                        type="close"
                                        color="white"
                                        size="large"
                                    />
                                </div>
                            </div>

                            {mainNav.length > 0 && (
                                <ul
                                    className={classNames(
                                        styles['Header__MainNav'],
                                        {
                                            [styles[
                                                'Header__MainNav--Expanded'
                                            ]]: !!expanded,
                                        }
                                    )}>
                                    {mainNav.map((x, i) => (
                                        <MainNavItem
                                            key={i}
                                            {...x}
                                            active={navActivePages.includes(
                                                x?.id
                                            )}
                                        />
                                    ))}
                                </ul>
                            )}
                        </div>

                        <ul
                            className={classNames(
                                styles['Header__MainNav--Desktop']
                            )}>
                            {mainNav.map((x, i) => (
                                <MainNavItem
                                    key={i}
                                    {...x}
                                    active={navActivePages.includes(x?.id)}
                                />
                            ))}
                        </ul>

                        {!!search && (
                            <LabeledIcon
                                type="search"
                                label={i18n.t('Header.search')}
                                color="white"
                                modifiers={[styles['Header__LabeledIcon']]}
                            />
                        )}
                        {bookmark.href && (
                            <LabeledIcon
                                type={
                                    myListCounter
                                        ? 'bookmarkFilled'
                                        : 'bookmark'
                                }
                                href={bookmark.href}
                                extraLabel={
                                    myListCounter && myListCounter > 0
                                        ? myListCounter.toString()
                                        : ''
                                }
                                label={i18n.t('Header.list')}
                                color="white"
                                modifiers={[styles['Header__LabeledIcon']]}
                                extraLabelMod={[styles['Header__SavedCounter']]}
                            />
                        )}

                        {!!search && (
                            <form className={styles['Header__SearchForm']}>
                                <input
                                    className={styles['Header__SearchInput']}
                                    type="search"
                                    placeholder={i18n.t('Header.search')}
                                    aria-label={i18n.t('Header.search')}
                                />
                                <button
                                    className={'Header__SearchButton'}
                                    type="submit">
                                    {i18n.t('Header.search')}
                                </button>
                            </form>
                        )}

                        {!!languages && !!languages.length && (
                            <div className={styles['Header__LanguageDesktop']}>
                                <DropdownLinks items={languages} />
                            </div>
                        )}

                        <button
                            className={styles['Header__Toggle']}
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            type="button"
                            aria-controls="navbarContent"
                            aria-expanded="false"
                            aria-label={i18n.t('Header.toggleNav')}>
                            <div
                                className={
                                    styles['Header__ToggleBurgerWrapper']
                                }>
                                <div
                                    className={
                                        styles['Header__ToggleBurger'] +
                                        ' ' +
                                        styles['Header__ToggleBurger--1']
                                    }></div>
                                <div
                                    className={
                                        styles['Header__ToggleBurger'] +
                                        ' ' +
                                        styles['Header__ToggleBurger--2']
                                    }></div>
                                <div
                                    className={
                                        styles['Header__ToggleBurger'] +
                                        ' ' +
                                        styles['Header__ToggleBurger--3']
                                    }></div>
                            </div>
                        </button>
                    </div>
                </div>
            </nav>
        </>
    );
};

Header.propTypes = {
    mainNav: PropTypes.array,
    navActivePages: PropTypes.array,
    languages: PropTypes.array,
    search: PropTypes.object,
    cookieBar: PropTypes.object,
};

Header.defaultProps = {
    mainNav: [],
    navActivePages: [],
    languages: [],
    search: null,
    bookmark: {
        label: '',
        href: '',
    },
    cookieBar: {},
};

export default Header;
