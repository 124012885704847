import React, { useState } from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './ArticleEntry.module.scss';
import LabeledIcon from '../LabeledIcon';
import classNames from 'classnames';
import ShareModal from '../ShareModal';
import CollaborationText from '../CollaborationText';

const ArticleEntry = ({
    categories,
    title,
    preamble,
    displayActions = true,
    smallFonts = false,
    collaboration,
}) => {
    const [modalIsOpen, setModalOpen] = useState();
    const handleClose = () => {
        setModalOpen(false);
    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    return (
        <section
            className={classNames(styles['ArticleEntry'], {
                [styles['ArticleEntry--SmallFonts']]: smallFonts,
            })}>
            {(!!title || !!preamble) && (
                <>
                    {categories && (
                        <strong className={styles['ArticleEntry__Label']}>
                            <span className="sr-only">
                                {i18n.t('generic.categories')}
                                {': '}
                            </span>
                            {categories.map((x) => x.title).join(', ')}
                        </strong>
                    )}

                    <h1 className={styles['ArticleEntry__Title']}>{title}</h1>

                    {!!collaboration && (
                        <section
                            className={
                                styles['ArticleEntry__CollaborationContainer']
                            }>
                            <CollaborationText {...collaboration} />
                        </section>
                    )}

                    {displayActions && (
                        <section
                            className={styles['ArticleEntry__MetaContainer']}>
                            <div
                                className={
                                    styles['ArticleEntry__LabeledIconWrapper']
                                }>
                                <LabeledIcon
                                    type="share"
                                    onClick={handleOpenModal}
                                    label={i18n.t('generic.share')}
                                    color="black800"
                                    isVertical={true}
                                />
                            </div>
                        </section>
                    )}

                    <p className={styles['ArticleEntry__Preamble']}>
                        {preamble}
                    </p>
                </>
            )}

            <ShareModal
                title={title}
                isOpen={modalIsOpen}
                handleClose={handleClose}
            />
        </section>
    );
};

ArticleEntry.propTypes = {
    categories: PropTypes.array,
    title: PropTypes.string.isRequired,
    preamble: PropTypes.string,
    collaboration: PropTypes.object,
};

ArticleEntry.defaultProps = {
    categories: null,
    title: '',
    preamble: null,
    collaboration: null,
};

export default ArticleEntry;
