import React from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';
import Icon from '../../components/Icon';
import ButtonRound from '../../components/ButtonRound';
import { trackEvent } from '../../utils/datalayer';
import { isLinkExternal } from '../../utils/helpers';
import CookieConsent from '../CookieConsent';

const Footer = ({ menus, logo, socialMedia, contact, siteSetting }) => {
    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__BottomWrapper']}>
                <div className={styles['Footer__Grid']}>
                    <nav
                        aria-label={i18n.t('Nav.footer')}
                        className={styles['Footer__Nav']}>
                        <div className={styles['Footer__NavSection']}>
                            {logo && (
                                <div className={styles['Footer__LogoWrapper']}>
                                    <div
                                        className={
                                            styles['Footer__BrandLinkWrapper'] +
                                            ' ' +
                                            styles[
                                                'Footer__BrandLinkWrapper--Desktop'
                                            ]
                                        }>
                                        <a
                                            href="/"
                                            className={
                                                styles['Footer__Brand'] +
                                                ' ' +
                                                styles['Footer__Brand--Desktop']
                                            }>
                                            <span className="sr-only">
                                                {i18n.t('Footer.logoLinkText')}
                                            </span>
                                        </a>

                                        {logo && logo.renditions && (
                                            <img
                                                src={logo.renditions.small.src}
                                                alt={i18n.t(
                                                    'Footer.logoAltText'
                                                )}
                                                width="220"
                                                height="80"
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={styles['Footer__NavSection']}>
                            {!!contact && (
                                <div className={styles['Footer__Contact']}>
                                    <h2 className={styles['Footer__NavLabel']}>
                                        {i18n.t('generic.contact')}
                                    </h2>
                                    <div className={styles['Footer__Address']}>
                                        <div className={styles['Footer__Name']}>
                                            {contact.address.name}
                                        </div>
                                        <div
                                            className={
                                                styles['Footer__Street']
                                            }>
                                            {contact.address.street}
                                        </div>
                                        <div className={styles['Footer__Zip']}>
                                            {contact.address.zip}{' '}
                                            {contact.address.city}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            styles['Footer__ContactLinks']
                                        }>
                                        {!!contact?.phone && (
                                            <div
                                                className={
                                                    styles['Footer__Phone']
                                                }>
                                                <a
                                                    className={
                                                        styles[
                                                            'Footer__NewUnderline'
                                                        ]
                                                    }
                                                    href={`tel:${contact.phone}`}>
                                                    {contact.phone}
                                                </a>
                                            </div>
                                        )}
                                        {!!contact?.email && (
                                            <div
                                                className={
                                                    styles['Footer__Email']
                                                }>
                                                <a
                                                    className={
                                                        styles[
                                                            'Footer__NewUnderline'
                                                        ]
                                                    }
                                                    href={`mailto:${contact.email}`}>
                                                    {contact.email}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {menus &&
                            menus.map((x, i) => (
                                <div
                                    className={styles['Footer__NavSection']}
                                    key={i}>
                                    <h2 className={styles['Footer__NavLabel']}>
                                        <span className="sr-only">
                                            {i18n.t('generic.categories')}
                                            {': '}
                                        </span>
                                        {x.label}
                                    </h2>

                                    <ul className={styles['Footer__NavList']}>
                                        {x.children.map((child, i) => (
                                            <li
                                                className={
                                                    styles['Footer__NavItem']
                                                }
                                                key={i}>
                                                <a
                                                    className={
                                                        styles[
                                                            'Footer__NavLink'
                                                        ]
                                                    }
                                                    href={child.href}>
                                                    <span className="sr-only">
                                                        {child.label}
                                                    </span>
                                                </a>

                                                <span
                                                    className={
                                                        styles[
                                                            'Footer__NavLinkText'
                                                        ]
                                                    }>
                                                    {child.label}
                                                </span>

                                                {isLinkExternal(child.href) && (
                                                    <div
                                                        className={
                                                            styles[
                                                                'Footer__NavIconWrapper'
                                                            ]
                                                        }>
                                                        <Icon
                                                            type="external"
                                                            color="white"
                                                        />
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    {i === 0 &&
                                        !!siteSetting?.cookieConsent?.title && (
                                            <div
                                                className={
                                                    styles[
                                                        'Footer__CookieConsentWrapper'
                                                    ]
                                                }>
                                                <CookieConsent
                                                    {...siteSetting.cookieConsent}
                                                />
                                            </div>
                                        )}
                                </div>
                            ))}
                        {logo && (
                            <div className={styles['Footer__BrandLinkWrapper']}>
                                <a href="/" className={styles['Footer__Brand']}>
                                    <span className="sr-only">
                                        {i18n.t('Footer.logoLinkText')}
                                    </span>
                                </a>
                                <img
                                    className={styles['Footer__Logo']}
                                    src={logo.renditions.small.src}
                                    alt={i18n.t('Footer.logoAltText')}
                                    width="220"
                                    height="80"
                                />
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    menus: PropTypes.array,
    logo: PropTypes.object,
    socialMedia: PropTypes.array,
};

Footer.defaultProps = {
    menus: null,
    logo: null,
    socialMedia: null,
};

export default Footer;
