import React from 'react';
import classNames from 'classnames';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import { ucFirst } from '../../utils/caseconverters.js';
import styles from './GuideFilter.module.scss';

const GuideFilter = ({ text, color, style, onClick, active }) => {
    const hasColor = color ? true : false;
    return (
        <div
            style={style}
            className={classNames(
                styles['GuideFilter'],
                { [styles['GuideFilter--Active']]: active },
                hasColor ? styles['GuideFilter--' + ucFirst(color)] : ''
            )}
            onClick={onClick}>
            {text && (
                <span className={styles['GuideFilter__Text']}>{text}</span>
            )}
        </div>
    );
};

GuideFilter.propTypes = {
    color: PropTypes.oneOf([
        'blue',
        'green',
        'purple',
        'orange',
        'pink',
        'gray',
        'white',
    ]),
    text: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    active: PropTypes.bool,
};

GuideFilter.defaultProps = {
    style: null,
    color: null,
    text: null,
    onClick: () => null,
    active: false,
};

export default GuideFilter;
