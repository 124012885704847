import React, { Fragment } from 'react';
import moment from 'moment';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CardEvent from '../CardEvent';

import PropTypes from 'prop-types';
import styles from './EventList.module.scss';

const EventList = ({
    events,
    isMyList,
    showRemoveButtons,
    setActiveEvent,
    removeItemsFromList,
}) => {
    return (
        <div className={styles['EventList']}>
            <TransitionGroup enter exit appear>
                {events.map((event, i) => {
                    let prev = i - 1;
                    const startDate = event?.date;
                    let isNewDay =
                        prev === -1 ||
                        startDate.toString() !== events[prev]?.date.toString();

                    const startAt = startDate
                        ? moment(startDate, 'YYYY-MM-DD')
                        : null;

                    const dateRepresentation = startAt
                        ? {
                              day: startAt.format('D'),
                              month: startAt.format('MMM'),
                          }
                        : null;

                    return (
                        <CSSTransition
                            key={event.id}
                            classNames={{
                                enter: styles['EventList__animation-enter'],
                                enterActive:
                                    styles['EventList__animation-enter-active'],
                                exit: styles['EventList__animation-exit'],
                                exitActive:
                                    styles['EventList__animation-exit-active'],
                            }}
                            timeout={400}>
                            <Fragment>
                                {isNewDay && !isMyList && !showRemoveButtons && (
                                    <strong
                                        key={'header' + i}
                                        className={
                                            styles['EventList__DateHeading']
                                        }>
                                        {startAt && startAt.format('D MMM')}
                                    </strong>
                                )}

                                <CardEvent
                                    {...event}
                                    id={event.id}
                                    date={dateRepresentation}
                                    image={event.featuredImage}
                                    color={event.color}
                                    href={event.url || event.callToActionUrl}
                                    content={true}
                                    categories={event.categories.map(
                                        ({ title }) => title
                                    )}
                                    modifiers={[
                                        'PaddingVertical',
                                        'Line',
                                        isMyList ? 'MyList' : '',
                                        event.active ? 'Active' : '',
                                    ]}
                                    showRemoveButton={
                                        showRemoveButtons || isMyList
                                    }
                                    removeEvent={removeItemsFromList}
                                    setActive={setActiveEvent}
                                />
                            </Fragment>
                        </CSSTransition>
                    );
                })}
            </TransitionGroup>
        </div>
    );
};

EventList.propTypes = {
    events: PropTypes.array,
    loadMoreNumber: PropTypes.number,
    checked: PropTypes.bool,
    handleCheckbox: PropTypes.func,
    isMyList: PropTypes.bool,
    showRemoveButtons: PropTypes.bool,
    setActiveEvent: PropTypes.func,
    removeItemsFromList: PropTypes.func,
};

EventList.defaultProps = {
    events: [],
    loadMoreNumber: 5,
    checked: true,
    handleCheckbox: () => {},
    isMyList: false,
    showRemoveButtons: false,
    setActiveEvent: () => {},
    removeSavedId: () => {},
};

export default EventList;
